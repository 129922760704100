import React from 'react'
import "./Component3.css";

import c3Img from "../../assets/c3-img.webp";

const Component3 = () => {
  return (
    <>
      
      {/* MENTAL HEALTH SERVICES */}
      <div className="component3">
          <div className="component3-content container">

          <div className="c3-left">

            <h1>Welcome to Beach Street Rehab</h1>
            <h2>Addiction and Mental Health Treatment Facility in Orange County</h2>
            <h3>WHAT WE DO</h3>

            <p>
            At Beach Street Rehab, we provide personalized addiction treatment and mental health recovery programs tailored to each individual’s needs. Our evidence-based care includes an Intensive Outpatient Program (IOP) for flexible treatment and a Partial Hospitalization Program (PHP) for structured, comprehensive support.</p> <br />
            <p>Our compassionate team of addiction specialists and mental health professionals will help determine the right level of care for you or your loved one, ensuring a path to lasting recovery in a safe and supportive environment.</p>
            <h3>TREATMENT</h3>
            <p>At Beach Street Rehab, every client receives a personalized recovery and treatment plan within 30 days of admission. Progress is closely monitored and reassessed weekly through comprehensive treatment summaries.</p><br />
            <p>During these weekly evaluations, our certified drug and alcohol counselors ensure that both clients and counselors stay on track with actionable steps toward recovery. Each individualized treatment plan is carefully reviewed and approved by our clinical director to ensure the highest standard of care and lasting progress.</p>


          </div>




          <div className="c3-right">

            
            <div className='c3-right-img'>
              <img src={c3Img} alt="nurse" loading="lazy" />
            </div>
            

          </div>
          </div>
        </div>


    </>
  )
}

export default Component3
